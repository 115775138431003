
.eventView{
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  z-index: 900;
  transition: left ease-in-out .2s;
  background-color: #bef3cc;
}
.eventView.active{
  left: 0;
} 

.eventViewButton{
  display: inline-block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 999;
  transition: left ease-in-out .2s;
  font-size: 3vh;
}
.eventViewButton.active{
  left: calc(100vw - 50px);
}
.eventViewButton:hover{
  cursor: pointer;
}
