
.listItem{
  margin: 1.5vw 0;
  background-color: rgba(0,0,0,.1);
  padding-bottom: 1vh;
 }
 .listItem:last-child{
  margin-bottom: 5vh;
 }
 .listItem .header{
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: .5vh 1.5vw 0 1.5vh;
   font-weight: 600;
 }

 .listItem .body{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1.5vw 0 1.5vw;
  overflow: hidden;
  transition: max-height ease-in-out .2s;
  max-height: 0;
 }
 
.listItem .body .amount{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  white-space: nowrap;
}
.listItem .body .amount input.amount {
  margin: 2vh 1vw 2vh 0;
  max-width: 100px;
}
